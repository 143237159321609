import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { authConfig } from './auth.config';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private oauthService: OAuthService, private router: Router) {
    this.oauthService.configure(authConfig);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    console.debug('AuthGuard validToken: ' + this.oauthService.hasValidAccessToken());
    if (this.oauthService.hasValidAccessToken()) {
      return true;
    }

    this.router.navigate(['authentication/login']);
    return false;
  }
}