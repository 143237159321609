import { Component } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';

@Component({
    selector: 'ega-auth',
    templateUrl: "ega-auth-component.html"
})
export class EgaAuthComponent {

    constructor(private oauthService: OAuthService,
        private router: Router) {
    }

    public login() {
        this.oauthService.initImplicitFlow();
    }

    public logoff() {
        this.oauthService.logOut();
        this.router.navigate(['authentication/login']);
    }

    public get name() {
        let claims = this.oauthService.getIdentityClaims();
        if (!claims) return null;
        //return claims.given_name;
    }

    public isLoggedIn(){
        return this.oauthService.hasValidAccessToken();
        
    }

}