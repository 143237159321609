import { Component } from '@angular/core';
import { OAuthService, JwksValidationHandler } from 'angular-oauth2-oidc'; 

@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: []
})
export class AppBlankComponent {
  constructor(private oAuthService: OAuthService) { }
}
