
import { AuthConfig } from 'angular-oauth2-oidc';

export const authConfig: AuthConfig = {
    clientId: 'pruebas.gpsauriga.com',
    redirectUri: window.location.origin + '/',
    postLogoutRedirectUri: '',
    scope: 'trust',
    resource: '',
    rngUrl: '',
    oidc: false,
    requestAccessToken: true,
    options: null,
    issuer: 'https://sso2.gpsauriga.com/oauth2AuthorizationServer',
    clearHashAfterLogin: true,
    tokenEndpoint: 'https://sso2.gpsauriga.com/oauth2AuthorizationServer/oauth/token',
    userinfoEndpoint: 'https://sso2.gpsauriga.com/oauth2AuthorizationServer/user',
    responseType: 'token',
    showDebugInformation: true,
    silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
    silentRefreshMessagePrefix: '',
    silentRefreshShowIFrame: false,
    silentRefreshTimeout: 20000,
    dummyClientSecret: 'secret',
    requireHttps: 'remoteOnly',
    strictDiscoveryDocumentValidation: false,
    jwks: {
        keys: [
            {
                "kty": "RSA",
                "kid": "SBo6ma6bThGVD0Kpny2niqSJ4QSojAy0HTUalZNoiiw",
                "n": "imVzGjsCzLL7g_bdX-MxllZ-3HsGPC2sSpSOBYnI7G-EwJi_CNwUzbqXRPiAAMh3s386JSCfHXWtWxJN-keYqeMt1AVKvfhfpmPJCI6Avx_Bo38QE1egfvOmovEDq_-fzRRNCSPLuV0V3DHFeuNfcKwmA4FXn_mb2WZuNAiAENaMxmUX1lG85JXKkWUAzhNah1pF_Ay03LGLII-9de5fXyPM8KYGAwMcjF3dVZEHoPhdd-I-NLwc4rPRhtH7-B0ZjKBwymm8tCsB-1RvjZHdihpVUGHvtwEdCPOwtonS6Sb8rid8cS2U9dryupGN_iMKZXE7vWeL2T2fZlQldTLV-w",
                "e": "AQAB",
                "use": "sig"
            }
        ]
    },
    customQueryParams: null,
    silentRefreshIFrameName: 'angular-oauth-oidc-silent-refresh-iframe',
    timeoutFactor: 0.75,
    sessionCheckIntervall: 3000,
    sessionCheckIFrameName: 'angular-oauth-oidc-check-session-iframe',
    disableAtHashCheck: false,
    skipSubjectCheck: false,
    useHttpBasicAuthForPasswordFlow: true
}